.footer {
  padding: 2.0rem 5% 2.5rem 5%;
  background: var(--black);
  display: flex;
  flex-direction: column;
}

.footer > a {
  align-self: center;
}

.main_foot {
  display: flex;
  flex-direction: row;
  padding-top: 3rem;
  justify-content: space-evenly;
  color: #9f9f9f;
  font-family: Mulish;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.main_foot a {
  color: #9f9f9f;
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-right: 0.5rem;
}

header {
  padding-top: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17rem;
  color: #fff;
  text-align: center;
  font-family: Times New Roman;
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 152.5%; /* 91.5px */
  background: linear-gradient(91deg, #f54b64 3.02%, #ff9a6c 100%);
}

.about {
  display: flex;
  flex-direction: row-reverse;
  font-family: Mulish;
  align-items: center;
  justify-content: space-between;
}

.about_text {
  color: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}

.our {
  background: var(--black);
  padding: 1.2rem 2.5rem;
  border-radius: 10px;
}

.our h3 {
  color: #fff;

  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 214%;
}

.our p {
  font-size: 1rem;
  line-height: 193%;
}

.about img {
  width: 40%;
  height: auto;
}
.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--background);
}
.contact_text {
  text-align: center;
  color: #0d0d0d;
  font-family: Mulish;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 151.5%;
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.contact_text span {
  font-weight: 800;
}

.col {
  width: 100% !important;
  background: white !important;
}

.first-head {
  color: #0d0d0d;
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 151.5%; /* 37.875px */
}
.fifth-container {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 3rem;
}
.third {
  width: 40rem;
}
.fifth-component-1 {
  width: 40rem;
  color: #0d0d0d;
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 151.5%; /* 37.875px */
}
.fifth-component-2 {
  padding: 1.5rem 3rem;
  height: 10rem;
  border-radius: 10px;
  background: #0d0d0d;
  color: #fff;
  font-family: Mulish;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 151.5%; /* 37.875px */
  display: flex;

  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.fifth-cont {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.5rem;
  justify-content: space-between;
}

.fifth-cont .fifth-component-2 {
  max-width: 48%;
  height: 10rem;
}
.head {
  color: #fff;
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 151.5%; /* 37.875px */
}
.more-info p {
  margin-top: 4rem;
  color: #0d0d0d;
  text-align: center;
  font-family: Mulish;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 151.5%; /* 37.875px */
}

.register {
  background: var(--background);
  text-align: center;
}

.register h2 {
  color: #0d0d0d;
  font-family: Times New Roman !important;
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 152.5%;
}
.register p {
  color: #0d0d0d;
  font-family: Mulish;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 193%;
}

.div1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.celeb {
  font-family: Mulish;
}
.fifth h3 {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 151.5%;
  padding-bottom: 1rem;
}
.fifth p {
  font-family: Mulish;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 151.5%;
}

.div1 {
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  gap: 3rem;
}

.div2 {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.heb {
  background: var(--black);
  color: white;
  padding: 2rem 3rem 3.5rem 2rem;
  border-radius: 17px;
}

.faq {
  padding: 2.5rem 8%;
  font-family: Mulish;
  min-height: 80vh;
}

.faq_content {
  background: var(--black);
  margin-bottom: 0.7rem;
  padding: 1.2rem;
  border-radius: 17px;
  color: #bab8b8;
  font-size: 1rem;
  font-style: italic;
  font-weight: 700;
  line-height: 193%;
}

.faqq {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.faq_body {
  padding-top: 1.2rem;
}

.textt {
  padding: 0 8%;
  padding-bottom: 6rem !important;
  font-family: Mulish;
  font-size: 1rem;
}

.textt h3 {
  color: #0d0d0d;
  padding-bottom: 1rem;

  font-style: normal;
  font-weight: 800;
  line-height: 151.5%;
}

.textt p {
  color: #0d0d0d;
  font-style: italic;
  font-weight: 500;
  line-height: 193%;
  font-size: 1rem;
}

.h3 {
  color: #0d0d0d;
  text-align: center;
  font-family: Mulish;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  margin-bottom: 1rem;
}
.seventh {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  font-family: var(--font);
  padding-bottom: 6rem;
}
.tex {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.4rem;
}
.divv {
  height: 0.6rem;
  align-self: start;
  width: 0.6rem;
  background: #0d0d0d;
  margin-top: 5px;
}

.dix {
  height: 0.6rem !important;
  background: #0d0d0d;
  align-self: start;
  width: 0.8rem !important;
  margin-top: 5px;
}

.actual-text {
  font-size: 1rem;
  width: 98%;
  color: #0d0d0d;
  font-style: normal;
  font-weight: 500;
  line-height: 151.5%;
}

.story {
  font-family: var(--font);
  padding-top: 8rem;
}

.story_img {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 3rem;
}

.story img {
  width: 17rem;
  height: 19rem;
  float: left;
  margin-right: 3rem;
  margin-bottom: 30px;
}
.story h3 {
  font-family: Times New Roman;
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 102.5%;
  margin-bottom: 10px;
}
.story p {
  color: #0d0d0d;
  font-size: 1.2rem;
  padding-bottom: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 151.5%;
}
@media (max-width: 960px) {
  .about {
    display: block;
  }
  .about img {
    width: 100%;
    height: auto;
  }
  .about_text {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
}
@media screen and (max-width: 550px) {
  .div1 {
    display: block;
    flex-direction: column;
  }
  .main_foot{
    display: grid;
    gap: 12px;
    text-align: center;
  }

  .div2 {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .story img {
    display: block;
    width: 80%;
    height: 25rem;
    align-self: center;
    margin: 0;
    margin-bottom: 30px;
  }

  .story {
    display: flex;
    flex-direction: column;
  }
  .story-content h3 {
    margin-bottom: 1.5rem;
  }
}
