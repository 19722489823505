.navbar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 8%;
  height: 5rem;
  align-items: center;
  background: var(--black);
  position: fixed;
  z-index: 5000;
}

.navbar > a {
  background: linear-gradient(89deg, #f54b64 7.25%, #f78361 99.18%);
  background-clip: text;
  -webkit-background-clip: text;
  font-family: Comfortaa;
  -webkit-text-fill-color: transparent;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.nav_link {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  align-items: center;
}
.nav_drawer {
  display: none;
}

.nav_link a {
  color: #fff;
  font-family: Mulish;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.nav_link a:nth-child(2) {
  background: white;
  border-radius: 10px;
  padding: 0.5rem 2.5rem;
  color: #242a38;
  font-weight: 600;
}

.home {
  padding-top: 5rem;
}

.home_text {
  background-image: url("https://res.cloudinary.com/greenmouse-tech/image/upload/v1701953535/Gleemora/Rectangle_3_3_1_dhwn8c.png");
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0 8% 7rem 8%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.home h2 {
  color: #fff;
  font-family: Mulish;
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 152.5%;
  margin-bottom: 2.5rem;
}

.home h2 span {
  background: linear-gradient(90deg, #f54b64 46.55%, #f78361 69.9%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.boxing {
  padding: 3rem 8% 3rem 8%;
}

.why {
  padding: 3rem 8% 3rem 8%;
  background: #fff8f9;
}
.main {
  font-family: Mulish;
}
.main h2 {
  color: #0d0d0d;
  font-family: Times New Roman !important;
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 152.5%;
  padding-bottom: 1rem;
}

.why p {
  color: #0d0d0d;
  font-family: Mulish;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 193%;
}

.para_text {
  width: 85%;
}

.why_img {
  padding-top: 3rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: space-between;
}

.why_img img:nth-child(1) {
  width: 15rem;
  padding-left: 0;
  margin-left: -2.5rem;
}
.why_img img {
  max-width: calc(
    25% - 10px
  ); /* Adjust the width as needed (25% for 4 images in a row) */
  margin-bottom: 10px; /* Add some spacing between the images */
  height: 15rem;
  box-sizing: border-box;
}

.find {
  background: #0d0d0d;
  color: white;
  font-family: Mulish;
}

.find h2 {
  color: white;
}
.find p {
  font-family: Mulish;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 193%;
}

.find_img {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
  padding-top: 5rem;
}

.find_img div {
  max-width: calc(
    33% - 10px
  ); /* Adjust the width as needed (25% for 4 images in a row) */
  margin-bottom: 10px; /* Add some spacing between the images */
  box-sizing: border-box;
  text-align: center;
}

.find_img div img {
  width: 8rem;
  height: 8rem;
}

.find_img div h3 {
  font-size: 1.2rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  font-weight: 700;
  line-height: 214%;
}

.find_img div p {
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 193%; /* 42.46px */
}

.draw {
  padding-bottom: 0 !important;
  background: var(--background);
}
.part,
.founder {
  display: flex;
  flex-direction: row;
}
.part h2 {
  padding-bottom: 2rem;
}

.draw_text h2 {
  padding-bottom: 1rem !important;
}
.draw_text {
  text-align: right;
}
.draw_text p {
  font-family: Mulish;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 193%;
}

.part img {
  width: 20rem;
  height: 20rem;
}
.par {
  gap: 10rem;
  padding-top: 9rem;
}

.founder {
  gap: 10rem;
}
.founder div p {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 193%;
  padding-bottom: 3rem;
}

.beyond span {
  padding-left: 1rem;
  font-size: 1.2rem;
}

.founder img {
  width: 24rem;
  height: 22rem;
}

.did {
  padding-left: 8%;
  padding-bottom: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.did_img {
  width: 40%;
  z-index: 1;
}
.did_img div {
  padding-top: 2.8rem;
  position: absolute;
  display: block;
}
.did_img div svg {
  display: block;
  margin-top: 1rem;
  margin-left: 3.5rem;
  width: 4rem;
}
.rect {
  z-index: 10 !important;
  width: 25rem;
  height: 22rem;
}

.did_text {
  width: 100%;
  padding: 6rem 2.5rem 6rem 6rem;
  height: fit-content;
  border-radius: 0px 0px 0px 130px;
  background: linear-gradient(57deg, #f54b64 11.55%, #ff9a6c 98.1%);
}

.did_text p {
  margin-left: 2rem;
  color: #fff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 165%;
  padding-bottom: 2rem;
}

.white {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: -1rem;
  width: 6.1rem;
  z-index: -1;
}

.orange {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -3rem;
  width: 6.1rem;
  z-index: -1;
}

.wait {
  text-align: center;
  background: var(--background);
}

.wait h2 {
  padding-bottom: 0 !important;
}
.wait p {
  font-size: 1.2rem;
}
form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.5rem;
  gap: 0.7rem;
}

input,
textarea {
  width: 100%;
  padding: 1.2rem;
  background: var(--black) !important;
  border-radius: 17px;
  resize: none;
  font-size: 1.1rem;
  color: whitesmoke !important;
}

input,
textarea::placeholder {
  /* color: #9e9e9e; */
  font-style: italic;
  background: black;
}

textarea {
  height: 11rem;
}

.join_btn {
  border-radius: 100px;
  background: #0d0d0d;
  padding: 1.4rem 5.2rem;
  width: fit-content;
  align-self: center;
  color: #fff;
  text-align: center;
  font-family: Mulish;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 1rem;
}

.comm {
  background: var(--black);
  color: #fff;
  text-align: center;
  padding-bottom: 0 !important;
}

.comm h2 {
  color: #fff;
  padding-bottom: 1.8rem;
}

.comm h3 {
  color: #fff;
  padding-bottom: 1.8rem;
  text-align: center;
  font-family: Times New Roman;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 152.5%;
}
.grad-span {
  background: linear-gradient(90deg, #f54b64 46.55%, #f78361 69.9%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.comm span {
  background: linear-gradient(90deg, #f54b64 46.55%, #f78361 69.9%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.comm p {
  color: #fff;
  font-family: Mulish;
  padding-bottom: 1.3rem;
  /* font-size: 1.2rem; */
  font-style: normal;
  font-weight: 500;
  line-height: 193%; /* 48.25px */
}
.show-flex {
  display: flex;
}
.show-height {
  display: none;
}
.p {
  padding-bottom: 0 !important;
}

@media screen and (max-width: 975px) {
  .did {
    padding-top: 3rem;
  }
  .rect {
    width: 20rem;
    height: 17rem;
  }
  .why_img {
    justify-content: center;
  }
  .par,
  .founder {
    gap: 3rem;
  }
  .why_img {
    padding-top: 3rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: space-between;
  }
  .home h2 {
    color: #fff;
    font-family: Mulish;
    font-size: 2.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 152.5%;
    margin-bottom: 2.5rem;
  }

  .why_img img {
    width: 15rem;
    margin-bottom: 10px; /* Add some spacing between the images */
    height: 15rem;
  }
}

@media screen and (max-width: 750px) {
  .para_text {
    width: 100% !important;
    margin-right: 0 !important;
  }
  .find_img {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 5rem;
  }

  .find_img div {
    min-width: 80% !important;
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .part {
    flex-direction: column-reverse !important;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  /* .nav_link a:nth-child(1){
        display: none;
     } */
  .part img {
    width: 15rem;
    height: 15rem;
  }
  .par {
    flex-direction: column !important;
  }

  .par p {
    text-align: left !important;
  }

  .par h2 {
    text-align: left !important;
  }

  .founder {
    flex-direction: column;
  }
  .beyond span {
    font-size: 1rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .main h2 {
    text-align: center;
    font-size: 2.3rem;
  }

  .part,
  .par h2 {
    text-align: left !important ;
  }

  /* .part,.para_text,.par p{
        font-size: 1rem !important;
    } */
}

@media screen and (max-width: 629px) {
  .navbar {
    padding: 1rem 3%;
  }
  .navbar > a {
    font-size: 1.5rem;
  }
  .nav_link a {
    font-size: 0.8rem;
  }
  .nav_link {
    display: none;
  }
  .nav_drawer {
    display: block;
  }
  /* .nav_link {
        display: flex;
        gap: 1.3rem;
    } */
  .nav_link a:nth-child(2) {
    padding: 0.5rem 1rem;
  }
}

@media screen and (max-width: 550px) {
  .home_text {
    height: 80vh;
    padding: 5rem 8% 3rem 8%;
  }
  .home h2 {
    color: #fff;
    font-family: Mulish;
    font-size: 2.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 152.5%;
    margin-bottom: 2.5rem;
  }

  .rect {
    width: 17rem;
    height: 16rem;
  }
  .did_text {
    margin-left: 0 !important;
  }
  .navbar {
    padding: 1rem 3%;
  }
  .navbar > a {
    font-size: 1.5rem;
  }
  .nav_link a {
    font-size: 0.8rem;
  }
  .nav_link {
    display: none;
  }
  .nav_drawer {
    display: block;
  }
  /* .nav_link {
        display: flex;
        gap: 1.3rem;
    } */
  .nav_link a:nth-child(2) {
    padding: 0.5rem 1rem;
  }
  .story img {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 310px) {
  .nav_link a:nth-child(1) {
    display: none;
  }
  .home_text {
    height: 85vh;
    padding: 5rem 8% 3rem 8%;
  }
  .home h2 {
    color: #fff;
    font-family: Mulish;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 600;
    line-height: 152.5%;
    margin-bottom: 2.5rem;
  }
  .show-height {
    display: none;
  }
  .show-flex {
    display: flex;
  }
}
@media screen and (max-width: 290px) {
  .show-height {
    display: block;
  }
  .show-flex {
    display: block;
  }
}
